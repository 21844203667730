<template lang="pug">
include ../../../configs/template
div.row.text-left
  div.col-sm-12.col-md-6
    +data-info('number', 'protocol.number_document')
    router-link(
      v-if="protocol.statement_dkk"
      :to="{ name: 'sqc-statements-info', params: { documentID: protocol.statement_dkk }}").ml-2 ({{ $t('openStatement') }})
  div.col-sm-12.col-md-6
    +data-info('dataEvent', 'getDateFormat(protocol.date_meeting) || "-"')
  div.col-sm-12.col-md-6
    +data-info('rank', 'rankByID(protocol.rank)[nameLang]')
  div.col-sm-12.col-md-6
    b {{ $t('position') }}:
    span(v-for="position in protocol.list_positions" :key="position.id") {{ positionByID(position)[nameLang] }};
  div.col-sm-12.col-md-4
    +data-info('headCommission', 'protocol.headCommission ? protocol.headCommission.user_fio_ukr : ""')
  div.col-sm-12.col-md-4
    +data-info('secretaryCommission', 'protocol.secretaryCommission ? protocol.secretaryCommission.user_fio_ukr : ""')
  div.col-sm-12.col-md-4
    b {{ $t('membersCommission') }}:
    span(v-for="member in protocol.membersCommission" :key="member.id") {{ member.user_fio_ukr }};
  div(v-if="checkAccess('document-author-view') && protocol.created_by").col-sm-12.col-md-6
    +data-info('recordAuthor', 'protocol.created_by.name')
  div(v-if="checkAccess('document-author-view') && protocol.created_by").col-sm-12.col-md-6
    +data-info('createDate', 'protocol.created_by.date')
  div.col-12
    +data-info('solution', 'decisionByID(protocol.decision)[nameLang]')(:class="getDecisionStatus(protocol.decision)")
  div.col-12
    +data-info('status', 'statusById(protocol.status_document)[nameLang]')(:class="getStatus(protocol.status_document)")
  div(v-if="checkAccess('backoffice') && protocol.cause_of_failure").col-sm-12.col-md-6
    +data-info('causeOfFailure', 'getDirectoryObject({ id: protocol.cause_of_failure, value: "listCauseOfFailure" })[nameLang]')
  div.col-12.mt-3
    v-btn(
      v-if="checkAccess('sailorSQCProtocols', 'download', protocol)"
      @click="saveDocument"
      :loading="isDocumentSavingLoading"
      color="primary").mr-3 {{ $t('saveProtocol') }}
    v-btn(
      v-if="checkAccess('sailorSQCProtocols', 'regeneration', protocol)"
      @click="regenerateProtocol"
      :loading="isDocumentRegenerationLoading"
      color="primary").mr-3 {{ $t('regenerateDoc') }}
    v-btn(
      v-if="checkAccess('sailorSQCProtocols', 'downloadWithStamps', protocol) && protocol.cifra_uuid"
      @click="saveDocWithSign"
      :loading="isDocumentSigningLoading"
      color="primary").mr-3 {{ $t('saveDocWithSign') }}
</template>

<script>
import { getStatus, regenerationConfirmation, getDateFormat, getDecisionStatus } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapActions, mapState, mapGetters } from 'vuex'
import { sailor } from '@/configs/endpoints'
export default {
  props: {
    protocol: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      isDocumentSigningLoading: false,
      isDocumentSavingLoading: false,
      isDocumentRegenerationLoading: false,
      getDateFormat,
      checkAccess,
      getStatus,
      getDecisionStatus
    }
  },
  computed: {
    ...mapGetters(['rankByID', 'positionByID', 'statusById', 'decisionByID', 'getDirectoryObject']),
    ...mapState({
      id: state => state.sailor.sailorId,
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    })
  },
  methods: {
    ...mapActions(['getDocument', 'getProtocolWithConclusion']),

    async saveDocument () {
      const data = {
        body: { doc_id: this.protocol.id },
        number: this.protocol.number_document,
        notify: 'uploadedFile'
      }
      this.isDocumentSavingLoading = true
      await this.getProtocolWithConclusion(data)
      this.isDocumentSavingLoading = false
    },

    saveDocWithSign () {
      this.isDocumentSigningLoading = true
      let body = {
        url: sailor.sqc.protocol.watermark(this.protocol.id),
        fileName: `Copy of Document N ${this.protocol.number}`,
        contentType: 'application/pdf'
      }
      this.getDocument(body).finally(() => { this.isDocumentSigningLoading = false })
    },

    async regenerateProtocol () {
      regenerationConfirmation().then(async confirmation => {
        if (confirmation) {
          this.isDocumentRegenerationLoading = true
          const data = {
            body: { doc_id: this.protocol.id, action: 'update' },
            number: this.protocol.number_document,
            notify: 'regenerationFileAndUploadNewFile'
          }
          await this.getProtocolWithConclusion(data)
          this.isDocumentRegenerationLoading = false
        }
      })
    }
  }
}
</script>
